import React from 'react';

const FAQ = () => {
  const faqItems = [
    {
      question: "What is this?",
      answer: "Pixel8s are 10,000 unique NFTs comprised of expertly hand-drawn pixel art that is entirely exclusive to Apechain."
    },
    {
      question: "When can I mint?",
      answer: "You are able to mint right now while supplies last!"
    },
    {
      question: "What is the cost?",
      answer: "While mint supply lasts, Pixel8 NFTs cost 2.5 Apecoins each to mint."
    },
    {
      question: "Rarity?",
      answer: "There are 8 types of Pixel8s, each with an equal rarity distribution. There are also 16 unique Pixel8s that are each 1-of-1s."
    },
    {
      question: "What else?",
      answer: "The development team is reserving 40 Pixel8s for themselves and 1 Unique Pixel8 as part of the Pixel8 mint puzzle prize pool."
    },
    {
      question: "Mint puzzle?",
      answer: "Keep your 👀's open."
    }
  ];

  return (
    <div className="faq">
      <h1>FAQ</h1>
      {faqItems.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-head">{item.question}</div>
          <div className="faq-body">{item.answer}</div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;