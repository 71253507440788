import React, { useState, useEffect } from 'react';
import { useContractRead } from 'wagmi';
import { useContract } from '../providers/ContractProvider';

const MintStatus = () => {
  const [progress, setProgress] = useState(0);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [remaining, setRemaining] = useState(0);
  const { nftContract, isConnected } = useContract();

  const { data: totalSupply } = useContractRead({
    ...nftContract,
    functionName: 'totalSupply',
    enabled: isConnected,
  });

  const { data: maxSupply } = useContractRead({
    ...nftContract,
    functionName: 'MAX_SUPPLY',
    enabled: isConnected,
  });

  useEffect(() => {
    if (totalSupply && maxSupply) {
      const total = Number(totalSupply);
      const max = Number(maxSupply);
      const calculatedProgress = (total / max) * 100;
      setProgress(calculatedProgress);
      setIsSoldOut(total >= max);
      setRemaining(max - total);
    }
  }, [totalSupply, maxSupply]);

  return (
    <div className="stats">
      <h1>MINT STATUS</h1>
      <div className="progress-bar__wrapper">
        <label className="progress-bar__value" htmlFor="progress-bar">
          {isSoldOut ? '100' : progress.toFixed(2)}%
        </label>
        <progress 
          id="progress-bar" 
          value={isSoldOut ? 100 : progress} 
          max="100"
        ></progress>
      </div>
      {!isSoldOut ? (
        <div className="remaining">{remaining} remaining</div>
      ) : (
        <div className="remaining">
          <span className="soldout">Sold out!</span> Buy on <a href="#">Magic Eden</a>
        </div>
      )}
    </div>
  );
};

export default MintStatus;