import "./App.css";
import { WagmiProvider, createConfig, http } from "wagmi";
import { ConnectKitProvider } from "connectkit";
import { ContractProvider } from "../providers/ContractProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Shell from "../components/Shell";

// Define custom chains
const apechain = {
  id: 33139,
  name: 'Apechain',
  network: 'apechain',
  nativeCurrency: {
    decimals: 18,
    name: 'Apecoin',
    symbol: 'APE',
  },
  rpcUrls: {
    public: { http: ['https://rpc.apechain.com/http'] },
    default: { http: ['https://rpc.apechain.com/http'] },
  },
  blockExplorers: {
    default: { name: 'Apechain Explorer', url: 'https://apechain.calderaexplorer.xyz/' },
  },
};

const curtis = {
  id: 33111,
  name: 'Curtis',
  network: 'curtis',
  nativeCurrency: {
    decimals: 18,
    name: 'Apecoin',
    symbol: 'APE',
  },
  rpcUrls: {
    public: { http: ['https://curtis.rpc.caldera.xyz/http'] },
    default: { http: ['https://curtis.rpc.caldera.xyz/http'] },
  },
  blockExplorers: {
    default: { name: 'Curtis Explorer', url: 'https://curtis.explorer.caldera.xyz/' },
  },
  testnet: true,
};

const wagmiConfig = createConfig({
  chains: [apechain, curtis],
  connectors: [],
  ssr: true,
  transports: {
    [apechain.id]: http(),
    [curtis.id]: http(),
  },
});

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <ConnectKitProvider>
          <ContractProvider>
            <Shell />
          </ContractProvider>
        </ConnectKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
