import React from 'react';
import Logo from './Logo';
import Discover from './Discover';
import MintStatus from './MintStatus';
import Connect from './Connect';
import FAQ from './FAQ';
import Socials from './Socials';
import Contract from './Contract';
import Examples from './Examples';

const Shell = () => {
  return (
    <div className="shell">
      <Logo />
      <Discover />
      <div className="content-wrapper">
      <hr />
        <MintStatus />
        <hr />
        <Connect />
        <hr />
        <FAQ />
        <hr />
        <Socials />
        <hr />
        <Contract />
        <hr />
        <Examples />
      </div>
    </div>
  );
};

export default Shell;