import React from "react";
import { ConnectKitButton } from "connectkit";
import { useAccount } from "wagmi";
import styled from "styled-components";
import DisconnectDialog from "./DisconnectDialog";

const StyledButton = styled.button`
	cursor: pointer;
	position: relative;
	display: inline-block;
	padding: 10px 18px;
	color: #ffffff;
	background: #1a88f8;
	font-size: 14px;
	font-weight: 500;
	border-radius: 10rem;

	transition: 200ms ease;
	&:hover {
		transform: translateY(-6px);
		box-shadow: 0 8px 20px -8px #1a88f8;
	}
	&:active {
		transform: translateY(-3px);
		box-shadow: 0 8px 12px -9px #1a88f8;
	}
`;

export default function AccountConnect() {
	const { address, isConnected } = useAccount();

	return (
		<div className="flex flex-row w-full items-center justify-center space-2 mt-2">
			{!isConnected ? (
				<ConnectKitButton.Custom>
					{({ show }) => (
						<div className="button" onClick={show}>
							<div className="text-base flex flex-col justify-center items-center align-middle h-full">Connect Wallet</div>
						</div>
					)}
				</ConnectKitButton.Custom>
			) : (
				<>
					<div className="flex flex-col text-sm">
						<b>{address}</b>
					</div>
					<DisconnectDialog />
				</>
			)}
		</div>
	);
}
