import React, { useState } from "react";
import { useDisconnect } from "wagmi";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Button } from "../ui/button";

export default function DisconnectDialog() {
	const { disconnect } = useDisconnect();
	const [isOpen, setIsOpen] = useState(false);

	const handleDisconnect = () => {
		disconnect();
		setIsOpen(false);
	};

	return (
		<Dialog open={isOpen} onOpenChange={setIsOpen}>
			<DialogTrigger asChild>
				<Button type="button" variant="destructive" className="pointer-events-auto" onClick={() => setIsOpen(true)}>
					Disconnect Wallet
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px] bg-black">
				<DialogHeader>
					<DialogTitle>Disconnect Wallet</DialogTitle>
					<DialogDescription>Are you sure you want to disconnect your wallet?</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button type="button" variant="secondary" onClick={() => setIsOpen(false)}>
						Cancel
					</Button>
					<Button type="button" variant="destructive" onClick={handleDisconnect}>
						Disconnect
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
