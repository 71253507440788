import React, { useState, useEffect } from 'react';
import { useAccount, useContractRead, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import logo from '../images/logo.png';
import apecoin from '../images/apecoin.png';
import AccountConnect from './account-connect/AccountConnect';
import DisconnectDialog from './account-connect/DisconnectDialog';
import { useContract } from '../providers/ContractProvider';

const Connect = () => {
  const [amount, setAmount] = useState(1);
  const { isConnected } = useAccount();
  const { nftSaleContract, networkName } = useContract();
  const [txStatus, setTxStatus] = useState('');
  const [price, setPrice] = useState(0);

  const { data: priceData } = useContractRead({
    ...nftSaleContract,
    functionName: 'PRICE',
  });

  const { writeContract, data: hash, error } = useWriteContract();

  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });

  useEffect(() => {
    if (priceData) {
      setPrice(Number(priceData) / 10**18); // Convert from wei to APE
    }
  }, [priceData]);

  const handleSliderChange = (e) => {
    setAmount(Number(e.target.value));
  };

  const handleMint = async () => {
    if (!isConnected) {
      alert('Please connect your wallet first.');
      return;
    }

    try {
      const result = await writeContract({
        ...nftSaleContract,
        functionName: 'mint',
        args: [amount],
        // eslint-disable-next-line no-undef
        value: BigInt(amount * price * 10**18), // Convert APE to wei
      });

      setTxStatus('Transaction sent. Waiting for confirmation...');
    } catch (err) {
      console.error('Error minting:', err);
      setTxStatus('Error: ' + err.message);
    }
  };

  return (
    <div className="mint">
      <h1>Mint</h1>
      {isConnected ? (
        <>
          <p>
            How many <img src={logo} alt="Apecoin" className="h-4 inline-block" /> do you want to mint? <br />
            1 minimum, 100 max per wallet
          </p>
          <div className="slidecontainer">
            <input
              type="range"
              min="1"
              max="100"
              value={amount}
              className="slider"
              id="amountslider"
              onChange={handleSliderChange}
            />
          </div>
          <div className="button" onClick={handleMint}>
            <div className="text mintme">Mint {amount}</div>
          </div>
          <div className="cost">
            <div className="flex flex-col gap-2 items-center align-middle w-full">
              <div className='flex flex-row gap-2 items-center align-middle'>
                <div className="apelogo"><img src={apecoin} alt="Apecoin" /></div>
                <div className="apecost" id="apecost">{(amount * price).toFixed(2)}</div>
              </div>
            </div>
          </div>
          <p>Network: {networkName}</p>
          <p>Contract Address: {nftSaleContract.address}</p>
          {txStatus && <p className="mt-4">{txStatus}</p>}
          {hash && <p className="mt-2">Transaction Hash: {hash}</p>}
          {isConfirming && <p className="mt-2">Transaction is confirming...</p>}
          {isConfirmed && (
            <div className="mt-4">
              <p>Transaction confirmed!</p>
              <button onClick={() => setTxStatus('')} className="mt-2 px-4 py-2 bg-blue-500 text-white rounded">
                Return to Mint
              </button>
            </div>
          )}
          {error && <p className="mt-2 text-red-500">Error: {error.message}</p>}
          <div className='mt-4'>
            <DisconnectDialog />
          </div>
        </>
      ) : (
       <div className="connect-wallet flex flex-col items-center justify-center align-middle">
          <p>Connect your wallet to mint Pixel8s</p>
          <AccountConnect />
        </div>
      )}
    </div>
  );
};

export default Connect;