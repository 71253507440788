import React from 'react';

const Socials = () => {
  return (
    <div className="socials w-full flex flex-col items-center justify-center">
      <h1>Socials</h1>
      <div className="twitter">X <a href="https://x.com/pixel8snft" target="_blank">@pixel8snft</a></div>
    </div>
  );
};

export default Socials;