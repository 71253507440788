import React from 'react';
import { useContract } from '../providers/ContractProvider';

const Contract = () => {
  const { nftContract, networkName } = useContract();

  const explorerUrl = networkName === 'apechain' 
    ? `https://apechain.calderaexplorer.xyz/address/${nftContract.address}`
    : `https://curtis.explorer.caldera.xyz/address/${nftContract.address}`;

  return (
    <div className="contract">
      Pixel8s Contract <br /> <br />
      <a href={explorerUrl} target="_blank" rel="noopener noreferrer">
        {nftContract.address}
      </a>
      <br />
      <small>Network: {networkName}</small>
    </div>
  );
};

export default Contract;