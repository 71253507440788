import React from 'react';
import exCowboy from '../images/ex-cowboy.png';
import secretWizard from '../images/secret-wizard.png';
import exAlien from '../images/ex-alien.png';

const Examples = () => {
  return (
    <div className="flex flex-row gap-4 w-full">
      <img src={exCowboy} alt="Example Cowboy" className="w-1/3 rounded-3xl" />
      <img src={secretWizard} alt="Secret Wizard" className="w-1/3 rounded-3xl" />
      <img src={exAlien} alt="Example Alien" className="w-1/3 rounded-3xl" />
    </div>
  );
};

export default Examples;