import React from 'react';
import alien from '../images/alien.png';
import cowboy from '../images/cowboy.png';
import discover from '../images/discover.png';
import pirate from '../images/pirate.png';
import skeleton from '../images/skeleton.png';

const Discover = () => {
  return (
    <div className="discover">
      <div className="packs flex justify-center items-center gap-2">
        <img src={alien} alt="Alien" className="w-24 h-auto" />
        <img src={cowboy} alt="Cowboy" className="w-24 h-auto" />
        <img src={discover} alt="Discover" className='h-auto' />
        <img src={pirate} alt="Pirate" className="w-24 h-auto" />
        <img src={skeleton} alt="Skeleton" className="w-24 h-auto" />
      </div>
    </div>
  );
};

export default Discover;