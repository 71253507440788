import React, { createContext, useContext, useMemo } from "react";
import { useAccount, useWriteContract, useWaitForTransactionReceipt, useChainId } from "wagmi";
import nftSaleAbi from "../utils/abis/Pixel8sNFTSale.js";
import nftAbi from "../utils/abis/Pixel8sNFT.js";

const ContractAddresses = {
	"Pixel8sNFTSale" : {
		"apechain": "0x458f694B016CC609B00838A257EBA5225A2537E0",
		"curtis": "0x36D2d777f3C43577357022CFE5c1a88B9Bb1DdA4",	
	},
	"Pixel8sNFT": {
		"apechain": "0x13cEA6501857B231c8632D8e6eA01a1Cc192A088",
		"curtis": "0xf5F01F1987Dcc772a0cB7d29b9101dc33F7d1D06",
	},
};

const ContractContext = createContext();

export const ContractProvider = ({ children }) => {
	const { address, isConnected } = useAccount();
	const { data: hash, error, writeContract } = useWriteContract();
	const chainId = useChainId();

	const {
		isLoading: isConfirming,
		isPending,
		isSuccess: isConfirmed,
	} = useWaitForTransactionReceipt({
		hash,
	});

	const networkName = useMemo(() => {
		if (chainId === 33139) return "apechain";
		if (chainId === 33111) return "curtis"; // Assuming curtis is Sepolia testnet
		return "unknown";
	}, [chainId]);

	const nftSaleContract = useMemo(() => ({
		address: ContractAddresses["Pixel8sNFTSale"][networkName] || "",
		abi: nftSaleAbi,
	}), [networkName]);

	const nftContract = useMemo(() => ({
		address: ContractAddresses["Pixel8sNFT"][networkName] || "",
		abi: nftAbi,
	}), [networkName]);

	const value = {
		address,
		isConnected,
		hash,
		error,
		isConfirming,
		isPending,
		isConfirmed,
		nftSaleContract,
		nftContract,
		networkName,
	};

	return <ContractContext.Provider value={value}>{children}</ContractContext.Provider>;
};

export const useContract = () => useContext(ContractContext);
