import React from 'react';
import logo from '../images/logo.png';

const Logo = () => {
  return (
    <div className="logo">
      <div className="header">
        <img src={logo} alt="Pixel8s Logo" className="w-full" />
      </div>
      <div className="subheader text-center text-xl mt-2">ApeChain exclusive NFT Collection</div>
    </div>
  );
};

export default Logo;